import React from "react"

import "./CardWrapper.css"

export const CardWrapper = ({children}) => {
    return (
        <div className={"card-wrapper"}>
            {children}
        </div>
    )
}