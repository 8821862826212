import React from 'react';
import "./App.css"

import {Header} from "./Components/Header/Header";
import {CardWrapper} from "./Components/CardWrapper/CardWrapper";
import {Card} from "./Components/Card/Card";
import {IPrediction, Prediction} from "./Components/Prediction/Prediction";

interface ISectors {
    [key: string]: IPrediction[]
}

export const App = () => {

    const [predictions, setPredictions] = React.useState<IPrediction[]>([])
    const [sectors, setSectors] = React.useState<ISectors>({})

    const mean = (predicions: IPrediction[], key: "change" | "pred"): number => {
        const sum = predicions.map(prediction => prediction[key]).reduce((a, b) => a+b, 0)
        return sum / predicions.length
    }

    const getQuote = async (symbol) => {
        try{
            const response = await fetch(`https://cdn.ccm.vc/stocks/index/${symbol}/latest.json`)
            const ch = await response.json()
            return parseFloat(ch["Global Quote"]["10. change percent"])
        }catch(e){
            return NaN
        }
    }

    const parsePredictions = async (textData) => {
        const sectors: ISectors = {}
        const overall: IPrediction[] = []
        const data = textData.trim().split("\n").slice(1, ).map((row, idx) => [idx, ...row.split(",")])
        await Promise.all(data.map(async (prediction) => {
            let [idx, symbol, sector, pred] = prediction
            const change = await getQuote(symbol)
            if(sector === "Communication Services"){
                sector = "Telcom"
            }
            overall.push({symbol, idx, pred: parseFloat(pred), sector, change, key: (Math.random() * new Date().getTime()).toString()})
            if(!(sector in sectors)){
                sectors[sector] = []
            }
            sectors[sector].push({symbol, idx, pred: parseFloat(pred), sector, change, key: (Math.random() * new Date().getTime()).toString()})
            sectors[sector].sort((a, b) => a.idx - b.idx)
        }))
        console.log(sectors)
        setPredictions(overall.sort((a, b) => a.idx - b.idx))
        setSectors(sectors)
    }

    const loadPredictions = async() => {
        const response = await fetch("https://cdn.ccm.vc/stocks/models/sp500-ensemble/latest.csv")
        parsePredictions(await response.text())
    }

    React.useEffect(() => {
        loadPredictions()
    }, [])

    return (
    <div className={"app"}>
      <Header/>
        <CardWrapper>
            <Card
                title={"Top 20"}
                pred={mean(predictions.slice(0, 20), "pred")}
                change={mean(predictions.slice(0, 20), "change")}
            >
                {
                    predictions.slice(0, 20).map((prediction, idx) => <Prediction key={prediction.key} prediction={prediction} idx={idx}/>)
                }
            </Card>
            <Card
                title={"Bottom 20"}
                pred={mean(predictions.slice(predictions.length - 20), "pred")}
                change={mean(predictions.slice(predictions.length - 20), "change")}
            >
                {
                    predictions.slice(predictions.length - 20).reverse().map((prediction, idx) => <Prediction key={prediction.key} prediction={prediction} idx={idx}/>)
                }
            </Card>
            {
                Object.keys(sectors).map(sector =>
                    <Card
                        key={sector}
                        title={sector}
                        pred={mean(sectors[sector], "pred")}
                        change={mean(sectors[sector], "change")}
                    >
                        {
                            sectors[sector].slice(0, 20).map((prediction, idx) => <Prediction key={prediction.key} prediction={prediction} idx={idx}/>)
                        }
                    </Card>
                )
            }
        </CardWrapper>
    </div>
    );
}

