import React from "react";

import "./PredictionConfidence.css"

export const PredictionConfidence = ({pred}) => {
    const [backgroundColor, setBackgroundColor] = React.useState("")
    const [width, setWidth] = React.useState("0%")

    const updateConfidenceBar = () => {
        const score = (pred - (-0.2)) / (0.3 - (-0.2))

        if(score > 0.3 && score < 0.5){
            setBackgroundColor("#ffc107")
        }else if(score >= 0.5){
            setBackgroundColor("#28a745")
        }else if(score <= 0.3){
            setBackgroundColor("#dc3545")
        }

        setWidth(`${score * 100}%`)
    }

    React.useEffect(() => {
        updateConfidenceBar()
    }, [pred])

    return (
        <div className={"prediction-confidence"}>
            <div className={"prediction-confidence-inner"} style={{backgroundColor, width}}></div>
        </div>
    )
}