import React from "react"

import "./Card.css"
import {PredictionConfidence} from "../PredictionConfidence/PredictionConfidence";
import {Quote} from "../Quote/Quote";

export const Card = ({title, children, pred, change}) => {
    return (
        <div className={"card"}>
            <div className={"card-title-wrapper"}>
                <h2 className={"card-title"}>{title}</h2>
                <PredictionConfidence pred={pred}/>
                <div className={"card-pred"}>{(pred * 100).toFixed(2)}%</div>
                <div className={"card-pred"}><Quote change={change}/></div>

            </div>
            <div className={"card-body"}>
                {children}
            </div>
        </div>
    )
}