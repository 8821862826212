import React from "react"

import {PredictionConfidence} from "../PredictionConfidence/PredictionConfidence"

import "./Prediction.css"
import {Quote} from "../Quote/Quote";

export interface IPrediction {
    symbol: string;
    pred: number;
    sector: string;
    key: string;
    change: number;
    idx: number;
}

export const Prediction = ({prediction, idx}: {prediction: IPrediction; idx: number}) => {
    return (
        <div className={"prediction"}>
            <div className={"prediction-row"} onClick={() => window.open(`https://finance.yahoo.com/quote/${prediction.symbol}/`, "_blank")}>
                [{idx + 1}]
            </div>
            <div className={"prediction-row"}>
                {prediction.symbol}
            </div>
            <div className={"prediction-row"}>
                <PredictionConfidence pred={prediction.pred}/>
            </div>
            <div className={"prediction-row"}>
                {(prediction.pred * 100).toFixed(2)}%
            </div>
            <div className={"prediction-row"}>
                <Quote change={prediction.change}/>
            </div>
        </div>
    )
}